import { Tool } from './Tool'
import GEOM_TYPES from './GeomTypes';

function HLineTool () {
    Tool.apply(this, arguments)
    this.type = GEOM_TYPES.HLine
}

HLineTool.prototype = Object.assign(Object.create(Tool.prototype),
    {
        constructor: HLineTool,
        startDrawing: function () {
            this._mousePos = new THREE.Vector3()
            this._raycasterForMouse = new THREE.Raycaster()
            Tool.prototype.startDrawing.apply(this, [2])
        },
        stopDrawing: function () {
            Tool.prototype.stopDrawing.apply(this, arguments)
        },

        groundMove: function (e) {

        },

        onMouseDownForMiddleClick: function (e) {

        },

        getRayDirection: function () {
            var cam = this.baseObject.getMainCamera()
            var mx = this.baseObject.globalMouseOffsetX
            var my = this.baseObject.globalMouseOffsetY

            var cnv = this.baseObject.getRendererDom()
            this._mousePos.x = (mx / cnv.width) * 2 - 1
            this._mousePos.y = -(my / cnv.height) * 2 + 1
            this._raycasterForMouse.setFromCamera(this._mousePos, cam)
            var rayDirection = this._raycasterForMouse.ray.direction
            return rayDirection
        },

        onMouseMove: function (e) {
            Tool.prototype.groundMove.apply(this, arguments)

            if (this._isMouseDown) {
                var ox = Math.abs(this.mouseDownX - e.offsetX)
                var oy = Math.abs(this.mouseDownY - e.offsetY)

                if (ox > 2 || oy > 2) {
                    this.isMoved = true
                }
            }

            if (e) {
                this.showMagnifier(e.offsetX, e.offsetY)
            }

            if (this.scalable.activeGeom.canDraw) {
                this.enableClick()
            } else {
                this.disableClick()
            }

            // this.throwEvent({type:Tool.STATUS_CHANGE, status:"POINT_MOVING"});

            var dt = this.baseObject.getDrawingPlugin()
            var gdh = dt.getCurrentGeometry()

            var rayDirection = this.getRayDirection()

            var y = rayDirection.y

            if (gdh.points.length) {
                var p = gdh.points[0]
                var pos = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)
                this.distanceInPixel = Math.sqrt((pos.x * pos.x) + (0) + (pos.z * pos.z))

                var pitch2 = Math.asin(y)
                var tan = Math.tan(pitch2)
                var opposite = this.distanceInPixel * tan

                opposite = opposite + -pos.y
                var realHeight = (opposite / this.scalable.camHegInPix) * this.scalable.camHegInMet

                this.lastPosition = { lat: p.lat, lon: p.lon, alt: p.alt + realHeight }
                this.throwEvent({ type: Tool.STATUS_CHANGE, status: 'MOUSE_MOVING', lat: p.lat, lon: p.lon, alt: p.alt + realHeight })
            } else {
                this.throwEvent({ type: Tool.STATUS_CHANGE, status: 'MOUSE_MOVING' })
            }
        },

        contextClick: function (e) {
            this.stopDrawing(this.counter === 1)
        },

        onGroundLeave: function () {
            // Tool.prototype.onGroundLeave.apply(this, arguments);
        },
        onGroundClick: function (e) {
            if (this.clickDisabled) return

            if (this.counter <= 1) {
                this.raisePointCount()
                this.throwEvent({ type: Tool.STATUS_CHANGE, status: 'POINT_ADDED', lon: e.lon, lat: e.lat, alt: e.alt })
            }
        },

        onMouseDown: function (e) {
            this.mouseDownX = e.offsetX
            this.mouseDownY = e.offsetY
            this.isMoved = false
            this._isMouseDown = true
        },

        domClick: function (e) {
            this._isMouseDown = false

            if (AnkaPanAPI.PanoGL.isPointerOnClickableObject) return

            if (this.isMoved) return

            if (this.counter === 1) {
                this.throwEvent({ type: Tool.STATUS_CHANGE, status: 'POINT_ADDED', lon: this.lastPosition.lon, lat: this.lastPosition.lat, alt: this.lastPosition.alt })

                this.stopDrawing(true)
            }
        }

    })

export { HLineTool }
