import {Tool} from './Tool'
import {LineTool} from './LineTool'
import GEOM_TYPES from './GeomTypes';

function SlopeLineTool () {
    Tool.apply(this, arguments)
    this.type = GEOM_TYPES.Line
}

SlopeLineTool.prototype = Object.assign(Object.create(LineTool.prototype),
    {
        constructor: SlopeLineTool,
        startDrawing: function () {
            Tool.prototype.startDrawing.apply(this, [2])
        },
        onMouseDownForMiddleClick: function (e) {

        }

    })

export {SlopeLineTool}
