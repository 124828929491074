import { DrawPlugin } from './DrawPlugin'
import { Magnifier } from './Magnifier'

Tool.STATUS_CHANGE = 'onStatusChange'

function Tool(panogl) {
    this.type = 'Tool'
    AnkaPanAPI.Base.apply(this, arguments)
    this.onMouseDownForMiddleClick = this.onMouseDownForMiddleClick.bind(this)
    this.baseObject = panogl
}

// Tool.prototype = Object.create(Base);
// Tool.prototype.constructor = Tool;

Tool.prototype = Object.assign(Object.create(AnkaPanAPI.Base.prototype),
    {
        constructor: Tool,
        isDrawing: false,
        pointcount: NaN,
        startDrawing: function (pointcount) {
            var t = this
            if (!t.isDrawing) {
                var plugins = t.baseObject.getPlugins()
                for (var i = 0; i < plugins.length; i++) {
                    if (plugins[i] instanceof AnkaScalable.ScalablePlugin) {
                        this.scalable = plugins[i]
                    } else if (plugins[i] instanceof AnkaSoftText.SoftTextPlugin) {
                        this.softText = plugins[i]
                    }
                }

                if (!this.scalable) {
                    console.log('Scalable Plugin bulunamadı. Çizim sahne üzerinde gösterilemeyebilir.')
                }

                if (!this.softText) {
                    console.log('SoftTextPlugin bulunamadı. label sahne üzerinde gösterilemeyebilir.')
                }

                if (this.scalable) {
                    this.scalable.disableMouse()
                }

                t.isDrawing = true
                t.pointcount = pointcount
                this.counter = 0
                var gm = t.baseObject.getGroundMaster()
                gm.disableNavigation()
                var g = AnkaPanAPI.GroundMaster

                gm.addEvent(g.GROUND_CLICK, this, this.onGroundClick)
                gm.addEvent(g.GROUND_MOVE, this, this.groundMove)
                gm.addEvent(g.GROUND_MOUSE_LEFT, this, this.onGroundLeave)
                t.baseObject.addEvent(AnkaPanAPI.PanoGL.CONTEXT_CLICK, this, this.contextClick)
                t.baseObject.addEvent(AnkaPanAPI.PanoGL.RENDER_DOM_CLICK, this, this.domClick)

                AnkaPanAPI.CursorManager.setCursor('crosshair', 666)
                t.baseObject.disableGroundNav(true)
                this.keyDownFunction = this.keyDownFunction === undefined ? this.onKeydown.bind(this) : this.keyDownFunction
                this.keyUpFunction = this.keyUpFunction === undefined ? this.onKeyup.bind(this) : this.keyUpFunction
                this.mouseMoveFunction = this.mouseMoveFunction === undefined ? this.onMouseMove.bind(this) : this.mouseMoveFunction
                this.mouseDownFunction = this.mouseDownFunction === undefined ? this.onMouseDown.bind(this) : this.mouseDownFunction

                document.addEventListener('keydown', this.keyDownFunction)
                document.addEventListener('keyup', this.keyUpFunction)
                this.baseObject.contentDV.addEventListener('mousemove', this.mouseMoveFunction)
                this.baseObject.contentDV.addEventListener('mousedown', this.mouseDownFunction)
                this.baseObject.contentDV.addEventListener('mousedown', this.onMouseDownForMiddleClick)

                this.throwEvent({ type: Tool.STATUS_CHANGE, status: 'STARTED' })
            } else {
                console.warn('Çizim bitirilmeden, yeni çizim başlatılamaz.')
            }
        },

        onMouseDownForMiddleClick: function (e) {
            if (e.button === 1) {
                if (this.counter > 0) {
                    this.throwEvent({ type: Tool.STATUS_CHANGE, status: 'LAST_POINT_REMOVED' })
                    this.downPointCount()
                }
            }
        },

        onGroundClick: function (e) {
        },

        onMouseDown: function (e) {

        },

        domClick: function (e) {

        },

        stopDrawing: function (isSuccessful) {
            if (this.isDrawing) {
                this.throwEvent({ type: Tool.STATUS_CHANGE, status: 'FINISHED', isSuccessful: isSuccessful })
            } else {
                console.warn('Çizim hiç başlatılmamış.')
            }
        },

        endDraw: function () {
            var t = this
            if (this.scalable) {
                this.scalable.enableMouse()
            }

            this.counter = 0
            t.isDrawing = false
            t.pointcount = NaN
            var gm = t.baseObject.getGroundMaster()
            var g = AnkaPanAPI.GroundMaster
            gm.removeEvent(g.GROUND_CLICK, this.onGroundClick)
            gm.removeEvent(g.GROUND_MOVE, this.groundMove)
            gm.removeEvent(g.GROUND_MOUSE_LEFT, this.onGroundLeave)
            t.baseObject.removeEvent(AnkaPanAPI.PanoGL.CONTEXT_CLICK, this.contextClick)

            t.baseObject.removeEvent(AnkaPanAPI.PanoGL.RENDER_DOM_CLICK, this.domClick)
            document.removeEventListener('keydown', this.keyDownFunction)
            document.removeEventListener('keyup', this.keyUpFunction)
            this.baseObject.contentDV.removeEventListener('mousemove', this.mouseMoveFunction)
            this.baseObject.contentDV.removeEventListener('mousedown', this.mouseDownFunction)
            this.baseObject.contentDV.removeEventListener('mousedown', this.onMouseDownForMiddleClick)

            if (this.magnifier) {
                this.magnifier.dispose()
            }

            this.magnifierAllowed = false

            t.throwEvent({ type: DrawPlugin.DRAW_ENDED })
            AnkaPanAPI.CursorManager.setCursor('auto', 666, true)
            this.baseObject.setDirty()

            gm.enableNavigation()
            t.baseObject.disableGroundNav(false)
        },

        raisePointCount: function () {
            this.counter++
        },

        downPointCount: function () {
            this.counter++
        },

        contextClick: function (e) {
            // console.log("TOOL contextClick");
        },

        disableClick: function () {
            // console.log("TOOL disableClick");
            this.clickDisabled = !0
        },

        enableClick: function () {
            // console.log("TOOL enableClick");
            this.clickDisabled = false
        },

        onGroundLeave: function (e) {
            // console.log("TOOL onGroundLeave");
            this.mouseOnGround = false
            AnkaPanAPI.CursorManager.setCursor('not-allowed', 666)
            if (this.magnifierAllowed && this.magnifier) {
                this.magnifier.hide()
            }
        },

        onMouseMove: function (e) {
            if (e) {
                this.currenX = e.offsetX
                this.currenY = e.offsetY
            }

            this.showMagnifier(this.currenX, this.currenY)
        },

        showMagnifier: function (mx, my) {
            if (this.magnifierAllowed) {
                // if(!Tool.magnifierImageData)
                // {
                //    Tool.magnifierImageData = this.magnifier.createImageData(30, 30);// new ImageData(width,height);
                //    if(!Tool.magnifierImageData)
                //    {
                //      return;
                //    }
                // }

                this.magnifier.renderImage(this.baseObject, mx, my)
            }
        },

        groundMove: function (e) {
            if (!AnkaPanAPI.PanoGL.isPointerOnClickableObject) {
                this.mouseOnGround = true
                if (this.magnifierAllowed && this.magnifier && !this.magnifier.visible) {
                    this.magnifier.show()
                }

                if (!this.clickDisabled) {
                    AnkaPanAPI.CursorManager.setCursor('crosshair', 666)
                } else {
                    AnkaPanAPI.CursorManager.setCursor('not-allowed', 666)
                }
            } else {
                AnkaPanAPI.CursorManager.setCursor('auto', -1, true)
            }
        },

        onKeydown: function (e) {
            if (e.keyCode === 16) {
                if (!this.magnifier) {
                    this.magnifier = new Magnifier(this.baseObject.contentDV)
                }

                this.magnifierAllowed = true

                if (this.mouseOnGround) {
                    this.magnifier.show()
                    this.onMouseMove()
                }
            } else if (e.keyCode === 27) {
                this.stopDrawing(false)
            }
        },
        onKeyup: function (e) {
            if (e.keyCode === 16) {
                if (this.magnifier) {
                    this.magnifier.hide()
                }
                this.magnifierAllowed = false
            }
        }
    })

export { Tool }
