import { PointTool } from './PointTool'
import { PolygonTool } from './PolygonTool'
import { LineTool } from './LineTool'
import { GridLineTool } from './GridLineTool'
import { HLineTool } from './HLineTool'
import { Tool } from './Tool'
import { Anchor3D } from './Anchor3D.js'
import { SGridWall } from './SGridWall.js'
import { SRoadLine } from './SRoadLine.js'
import { RoadTool } from './RoadTool.js'
import { SlopeLineTool } from './SlopeLineTool.js'
import GEOM_TYPES from './GeomTypes';
export { Magnifier } from './Magnifier.js'

DrawPlugin.STATUS_CHANGE = 'onStatusChange'
DrawPlugin.VERTEX_EDIT = 'onStatusChange'

DrawPlugin.STATES = {
    NONE: 1,
    POLYGON_DRAW: 2,
    LINE_DRAW: 3,
    HLINE_DRAW: 4,
    POINT_DRAW: 5,
    ROAD_LINE_DRAW: 6
}

DrawPlugin.GEOM_TYPES = GEOM_TYPES

DrawPlugin.prototype = Object.create(AnkaPanAPI.PanoPlugin.prototype)
DrawPlugin.prototype.constructor = DrawPlugin
DrawPlugin._instances = []

DrawPlugin.instanceCounter = 100
function DrawPlugin(ops) {
    AnkaPanAPI.PanoPlugin.apply(this, [])
    DrawPlugin._instances.push(this)
    this.currentState = DrawPlugin.STATES.NONE
    this._roll = 0
    this._heading = 0
    this._pitch = 0
    this.__anchorVertex = []
    DrawPlugin.instanceCounter++
    this.iin = DrawPlugin.instanceCounter

    this._drawingStarted = false

    this.ClassInstance = DrawPlugin
    this.initialOptions = ops
    if (ops) {
        for (var str in ops) {
            this['_' + str] = ops[str]
        }
    }
}

DrawPlugin.prototype.stopGeometryVertexEdit = function () {
    for (let i = 0; i < this.__anchorVertex.length; i++) {
        this.__anchorVertex[i].destroy()
        this.__anchorVertex[i].removeEvent(AnkaPanAPI.MeshMousePicker.CLICK, this.onAnchorClick)
    }

    DrawPlugin.__vertexEditGeom = null
    this.__anchorVertex.length = 0
}

DrawPlugin.prototype.editGeometryVertices = function (geomMesh, geometry) {
    this.stopGeometryVertexEdit()
    var points = []

    if (geometry.isMultiGeom) {
        for (let i = 0; i < geometry.points.length; i++) {
            for (let j = 0; j < geometry.points[i].length; j++) {
                points.push(geometry.points[i][j])
            }
        }
    } else {
        for (let i = 0; i < geometry.points.length; i++) {
            points.push(geometry.points[i])
        }
    }

    DrawPlugin.__vertexEditGeom = geometry

    var panogl = this.baseObject
    var camera = panogl.getMainCamera()
    var currentPoint = panogl.getCurrentPoint()
    var camHeightInMeter = panogl.getCameraHeight()
    var currentAlt = currentPoint.altitude - camHeightInMeter
    for (let i = 0; i < points.length; i++) {
        var p = points[i]
        var mesh = new Anchor3D(this, this.anchorScene, camera)
        mesh.setPoint(p, geomMesh, geometry)
        this.anchorScene.add(mesh)
        if (isNaN(p.alt) || p.alt === undefined || p.alt === null) {
            p.alt = currentAlt
        }
        var pxPos = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)
        mesh.position.set(pxPos.x, pxPos.y, pxPos.z)
        mesh.updateScale()
        mesh.addEvent(AnkaPanAPI.MeshMousePicker.CLICK, this, this.onAnchorClick)
        this.__anchorVertex.push(mesh)
        mesh.lookAtCamera()
    }
}

DrawPlugin.prototype.onAnchorClick = function (e) {
    var mesh = e.target
    this.selectAnchor(mesh)
}

DrawPlugin.prototype.stopDragController = function (b) {
    var panogl = this.baseObject
    var controller = panogl.getController()
    controller.disable(b)
}

DrawPlugin.prototype.onAnchorMouseDown = function (e) {
    var anchor = e.target
    this.stopDragController(true)
    this.selectAnchor(anchor)
    anchor.startDrag()
    this.mouseUpFNC = this.onMouseUpForAnchor.bind(this)
    document.addEventListener('mouseup', this.mouseUpFNC)
}

DrawPlugin.prototype.onMouseUpForAnchor = function (e) {
    this.__selectedAnchor.stopDrag()
    this.stopDragController(false)
    document.removeEventListener('mouseup', this.mouseUpFNC)
}

DrawPlugin.prototype.selectAnchor = function (achor) {
    this.clearAnchorSelection()
    this.__selectedAnchor = achor
    this.__selectedAnchor.addEvent(AnkaPanAPI.MeshMousePicker.MOUSE_DOWN, this, this.onAnchorMouseDown)
    achor.addEvent(Anchor3D.UPDATE, this, this.anchorUpdate)
    achor.setSelection(true)
}

DrawPlugin.prototype.anchorUpdate = function (e) {
    let ins = DrawPlugin._instances
    for (let i = 0; i < ins.length; i++) {
        if (ins[i] !== this) {
            ins[i].updateAnchorPositions()
        }

        var event = { type: DrawPlugin.VERTEX_EDIT, target: DrawPlugin.__vertexEditGeom }
        this.throwEventFowAll(event)
    }
}

DrawPlugin.prototype.updateAnchorPositions = function (e) {
    var anchor
    for (let i = 0; i < this.__anchorVertex.length; i++) {
        anchor = this.__anchorVertex[i]
        anchor.updatePosition()
    }
}

DrawPlugin.prototype.clearAnchorSelection = function () {
    if (this.__selectedAnchor) {
        this.__selectedAnchor.setSelection(false)
        this.__selectedAnchor.removeEvent(AnkaPanAPI.MeshMousePicker.MOUSE_DOWN, this.onAnchorMouseDown)
        this.__selectedAnchor.removeEvent(Anchor3D.UPDATE, this.anchorUpdate)
    }
    this.__selectedAnchor = null
}

DrawPlugin.prototype.prepare = function () {
    var bo = this.baseObject
    var plgns = bo.getPlugins()
    for (let i = 0; i < plgns.length; i++) {
        if (plgns[i] instanceof AnkaScalable.ScalablePlugin) {
            this.scalable = plgns[i]
            break
        }
    }

    var t = this
    bo.getDrawingPlugin = function () {
        return t
    }

    let ins = DrawPlugin._instances
    for (let i = 0; i < ins.length; i++) {
        if (ins[i] !== this) {
            if (ins[i].currentState !== DrawPlugin.STATES.NONE) {
                this.startSyncDrawByState(ins[i].currentState)
                break
            }
        }
    }

    this.anchorScene = new THREE.Scene()

    this.baseObject.addEvent(AnkaPanAPI.PanoGL.POST_RENDER, this, this.onPostRender)
}

DrawPlugin.prototype.onPostRender = function (e) {
    if (this.anchorScene && this.anchorScene.children.length > 0) {
        var render = this.baseObject.getRenderer()
        render.clearDepth()
        render.render(this.anchorScene, this.baseObject.getMainCamera())
    }
}

DrawPlugin.prototype.startSyncDrawByState = function (state) {
    if (state === DrawPlugin.STATES.POLYGON_DRAW) {
        this.scalable.updateAliveGeom()
        this.startSyncronPolygonDraw()
    } else if (state === DrawPlugin.STATES.LINE_DRAW) {
        this.scalable.updateAliveGeom()
        this.startSyncronLineDraw()
    } else if (state === DrawPlugin.STATES.POINT_DRAW) {
        this.scalable.updateAliveGeom()
        this.startSyncronPointDraw()
    } else if (state === DrawPlugin.STATES.HLINE_DRAW) {
        this.scalable.updateAliveGeom()
        this.startSyncronHLineDraw()
    }
}

DrawPlugin.prototype.clearDrawings = function (isSuccessful) {
    if (this.currentTool) {
        this.scalable.destroyActiveGeometry()
        this.currentTool.removeEvent(Tool.STATUS_CHANGE, this.drawingUpdate)
        this.currentState = DrawPlugin.STATES.NONE
        this.currentTool.stopDrawing(isSuccessful)
        this.endDraw()
    }
}

DrawPlugin.prototype.stopDrawingForAll = function (isSuccessful) {
    let ins = DrawPlugin._instances
    for (let i = 0; i < ins.length; i++) {
        ins[i].clearDrawings(isSuccessful)
    }
}

DrawPlugin.prototype.startHLine = function (layer, attributes) {
    this.clearDrawings()
    var dh = new AnkaScalable.GeomDataHolder(AnkaScalable.SHLine, [], {})
    dh.type = AnkaScalable.GeomDataHolder.HLINE
    dh.isDrawnData = true
    dh.setStatus(AnkaScalable.GeomDataHolder.STATUS.DRAWING)
    AnkaScalable.ScalablePlugin.activeGeomDataHolder = dh

    if (layer) {
        dh.layer = layer
    } else {
        this.sketchLayer = this.scalable.getMainSketchLayer()
        dh.layer = this.sketchLayer
    }
    dh.mergeAttributes(attributes)

    let ins = DrawPlugin._instances
    for (let i = 0; i < ins.length; i++) {
        ins[i].scalable.updateAliveGeom()
        ins[i].startSyncronHLineDraw()
    }
}

DrawPlugin.prototype.startSyncronHLineDraw = function () {
    this.clearDrawings()
    this.currentState = DrawPlugin.STATES.HLINE_DRAW
    this.currentTool = new HLineTool(this.baseObject)
    this.currentTool.addEvent(Tool.STATUS_CHANGE, this, this.drawingUpdate)
    this.currentTool.startDrawing()
}

// GRİD
DrawPlugin.prototype.startGridLine = function (layer, attributes) {
    this.clearDrawings()
    var dh = new AnkaScalable.GeomDataHolder(SGridWall, [], {})
    dh.type = AnkaScalable.GeomDataHolder.LINE
    dh.isDrawnData = true
    dh.setStatus(AnkaScalable.GeomDataHolder.STATUS.DRAWING)
    AnkaScalable.ScalablePlugin.activeGeomDataHolder = dh

    if (layer) {
        dh.layer = layer
    } else {
        this.sketchLayer = this.scalable.getMainSketchLayer()
        dh.layer = this.sketchLayer
    }
    dh.mergeAttributes(attributes)

    let ins = DrawPlugin._instances
    for (let i = 0; i < ins.length; i++) {
        ins[i].scalable.updateAliveGeom()
        ins[i].startSyncronGridLineDraw()
    }
}

DrawPlugin.prototype.startSyncronGridLineDraw = function () {
    this.clearDrawings()
    this.currentState = DrawPlugin.STATES.HLINE_DRAW
    this.currentTool = new GridLineTool(this.baseObject)
    this.currentTool.addEvent(Tool.STATUS_CHANGE, this, this.drawingUpdate)
    this.currentTool.startDrawing()
}
// END

DrawPlugin.prototype.startPoint = function (layer, attributes) {
    this.clearDrawings()
    var dh = new AnkaScalable.GeomDataHolder(AnkaScalable.SPoint, [], {})
    dh.isDrawnData = true
    dh.type = AnkaScalable.GeomDataHolder.POINT
    dh.setStatus(AnkaScalable.GeomDataHolder.STATUS.DRAWING)
    AnkaScalable.ScalablePlugin.activeGeomDataHolder = dh

    if (layer) {
        dh.layer = layer
    } else {
        this.sketchLayer = this.scalable.getMainSketchLayer()
        dh.layer = this.sketchLayer
    }

    dh.mergeAttributes(attributes)

    let ins = DrawPlugin._instances
    for (let i = 0; i < ins.length; i++) {
        ins[i].scalable.updateAliveGeom()
        ins[i].startSyncronPointDraw()
    }
}

DrawPlugin.prototype.startSyncronPointDraw = function () {
    this.clearDrawings()
    this.currentState = DrawPlugin.STATES.POINT_DRAW
    this.currentTool = new PointTool(this.baseObject)
    this.currentTool.addEvent(Tool.STATUS_CHANGE, this, this.drawingUpdate)
    this.currentTool.startDrawing()
}

// ROAD LINE START
DrawPlugin.prototype.startRoadLine = function (layer, attributes) {
    this.clearDrawings()
    var dh = new AnkaScalable.GeomDataHolder(SRoadLine, [], {})
    dh.isDrawnData = true
    dh.type = AnkaScalable.GeomDataHolder.LINE
    dh.setStatus(AnkaScalable.GeomDataHolder.STATUS.DRAWING)
    AnkaScalable.ScalablePlugin.activeGeomDataHolder = dh

    if (layer) {
        dh.layer = layer
    } else {
        this.sketchLayer = this.scalable.getMainSketchLayer()
        dh.layer = this.sketchLayer
    }

    dh.mergeAttributes(attributes)

    let ins = DrawPlugin._instances
    for (let i = 0; i < ins.length; i++) {
        ins[i].scalable.updateAliveGeom()
        ins[i].startSyncronRoadLineDraw()
    }
}

DrawPlugin.prototype.startSyncronRoadLineDraw = function () {
    this.clearDrawings()
    this.currentState = DrawPlugin.STATES.ROAD_LINE_DRAW
    this.currentTool = new RoadTool(this.baseObject)
    this.currentTool.addEvent(Tool.STATUS_CHANGE, this, this.drawingUpdate)
    this.currentTool.startDrawing()
}

// ROAD LINE END

DrawPlugin.prototype.startSlopeLine = function (layer, attributes) {
    this.clearDrawings()
    var dh = new AnkaScalable.GeomDataHolder(AnkaScalable.SHLine, [], {})
    dh.isDrawnData = true
    dh.type = AnkaScalable.GeomDataHolder.LINE
    dh.setStatus(AnkaScalable.GeomDataHolder.STATUS.DRAWING)
    AnkaScalable.ScalablePlugin.activeGeomDataHolder = dh

    if (layer) {
        dh.layer = layer
    } else {
        this.sketchLayer = this.scalable.getMainSketchLayer()
        dh.layer = this.sketchLayer
    }

    dh.mergeAttributes(attributes)

    let ins = DrawPlugin._instances
    for (let i = 0; i < ins.length; i++) {
        ins[i].scalable.updateAliveGeom()
        ins[i].startSyncronSlopeLineDraw()
    }
}

DrawPlugin.prototype.startSyncronSlopeLineDraw = function () {
    this.clearDrawings()
    this.currentState = DrawPlugin.STATES.HLINE_DRAW
    this.currentTool = new SlopeLineTool(this.baseObject)
    this.currentTool.addEvent(Tool.STATUS_CHANGE, this, this.drawingUpdate)
    this.currentTool.startDrawing()
}

// START LİNE
DrawPlugin.prototype.startLine = function (layer, attributes) {
    this.clearDrawings()
    var dh = new AnkaScalable.GeomDataHolder(AnkaScalable.SLine, [], {})
    dh.isDrawnData = true
    dh.type = AnkaScalable.GeomDataHolder.LINE
    dh.setStatus(AnkaScalable.GeomDataHolder.STATUS.DRAWING)
    AnkaScalable.ScalablePlugin.activeGeomDataHolder = dh

    if (layer) {
        dh.layer = layer
    } else {
        this.sketchLayer = this.scalable.getMainSketchLayer()
        dh.layer = this.sketchLayer
    }

    dh.mergeAttributes(attributes)

    let ins = DrawPlugin._instances
    for (let i = 0; i < ins.length; i++) {
        ins[i].scalable.updateAliveGeom()
        ins[i].startSyncronLineDraw()
    }
}

DrawPlugin.prototype.startSyncronLineDraw = function () {
    this.clearDrawings()
    this.currentState = DrawPlugin.STATES.LINE_DRAW
    this.currentTool = new LineTool(this.baseObject)
    this.currentTool.addEvent(Tool.STATUS_CHANGE, this, this.drawingUpdate)
    this.currentTool.startDrawing()
}

/// START Polygon
DrawPlugin.prototype.startPolygon = function (layer, attributes) {
    this.clearDrawings()
    var dh = new AnkaScalable.GeomDataHolder(AnkaScalable.SPolygon, [], attributes)
    dh.type = AnkaScalable.GeomDataHolder.POLYGON
    dh.isDrawnData = true
    dh.setStatus(AnkaScalable.GeomDataHolder.STATUS.DRAWING)
    AnkaScalable.ScalablePlugin.activeGeomDataHolder = dh

    if (layer) {
        dh.layer = layer
    } else {
        this.sketchLayer = this.scalable.getMainSketchLayer()
        dh.layer = this.sketchLayer
    }

    let ins = DrawPlugin._instances
    for (let i = 0; i < ins.length; i++) {
        ins[i].scalable.updateAliveGeom()
        ins[i].startSyncronPolygonDraw()
    }
}

DrawPlugin.prototype.startSyncronPolygonDraw = function () {
    this.clearDrawings()
    this.currentState = DrawPlugin.STATES.POLYGON_DRAW
    this.currentTool = new PolygonTool(this.baseObject)
    this.currentTool.addEvent(Tool.STATUS_CHANGE, this, this.drawingUpdate)
    this.currentTool.startDrawing()

    this.throwEvent({ type: DrawPlugin.DRAW_STARTED, target: AnkaScalable.ScalablePlugin.activeGeomDataHolder, drawType: this.getGeomType() })
}

DrawPlugin.prototype.getGeomType = function () {
    if (this.currentState === DrawPlugin.STATES.POLYGON_DRAW) {
        return DrawPlugin.GEOM_TYPES.Polygon
    } else if (this.currentState === DrawPlugin.STATES.HLINE_DRAW) {
        return DrawPlugin.GEOM_TYPES.HLine
    } else if (this.currentState === DrawPlugin.STATES.LINE_DRAW) {
        return DrawPlugin.GEOM_TYPES.Line
    } else if (this.currentState === DrawPlugin.STATES.POINT_DRAW) {
        return DrawPlugin.GEOM_TYPES.Point
    } else if (this.currentState === DrawPlugin.STATES.ROAD_LINE_DRAW) {
        return DrawPlugin.GEOM_TYPES.RoadLine
    }

    return this.activeGeomType
}

DrawPlugin.prototype.drawingUpdate = function (e) {
    var dh = AnkaScalable.ScalablePlugin.activeGeomDataHolder

    // TODO REFACTOR
    e.target = dh
    e.feature = dh

    if (e.status === 'POINT_MOVING') {
        e.drawType = this.getGeomType()
        dh.setDynamicPoint({ lat: e.lat, lon: e.lon, alt: e.alt })
        this.throwEventFowAll(e)
    } else if (e.status === 'LAST_POINT_REMOVED') {
        dh.removeLastPoint()
        e.drawType = this.getGeomType()
        this.throwEventFowAll(e)
    } else if (e.status === 'POINT_ADDED') {
        dh.addPoint({ lat: e.lat, lon: e.lon, alt: e.alt })
        e.drawType = this.getGeomType()
        this.throwEventFowAll(e)
    } else if (e.status === 'FINISHED') {
        var drawType = this.getGeomType()
        let ins = DrawPlugin._instances
        for (let i = 0; i < ins.length; i++) {
            ins[i].endDraw()
        }

        if (e.isSuccessful) {
            dh.setStatus(AnkaScalable.GeomDataHolder.STATUS.COMPLETED)
            var layer = dh.layer
            // layers inherited SketchLayer has a feature that distrubutes a geomtry to other sketch layers.
            if (layer.isSketchLayer) {
                layer.addToList(dh)
                let ins = layer.getLayerInstances()
                for (let i = 0; i < ins.length; i++) {
                    ins[i].drawGeometry(dh)
                }
            } else {
                let ins = layer.getLayerInstances()
                for (let i = 0; i < ins.length; i++) {
                    ins[i].addToList(dh)
                    ins[i].drawGeometry(dh)
                }
            }
        } else {
            dh.setStatus(AnkaScalable.GeomDataHolder.STATUS.BAD_COMPLETED)
        }

        // Destroy the active geometry which used to draw geometry dynamiclly.
        this.scalable.destroyActiveGeometryForAllInstance()
        e.drawType = drawType
        e.target = dh
        this.throwEventFowAll(e)
    } else if (e.status === 'MOUSE_MOVING') {
        dh.setDynamicPoint({ lat: e.lat, lon: e.lon, alt: e.alt })
        dh.throwEvent({ type: AnkaScalable.GeomDataHolder.CHANGE, target: dh })
        e.drawType = this.getGeomType()
        this.throwEventFowAll(e)
    } else if (e.status === 'STARTED') {
        e.drawType = this.getGeomType()
        this.throwEventFowAll(e)
    }
}

DrawPlugin.prototype.throwEventFowAll = function (event) {
    let ins = DrawPlugin._instances
    for (let i = 0; i < ins.length; i++) {
        ins[i].setDirty()
        ins[i].throwEvent(event)
    }
}

/// END Polygon

DrawPlugin.prototype.endDraw = function () {
    this.currentState = DrawPlugin.STATES.NONE
    AnkaScalable.ScalablePlugin.activeGeomDataHolder = null
    this.currentTool.endDraw()
    this.currentTool.removeEventWithContext(Tool.STATUS_CHANGE, this, this.drawingUpdate)
    this.currentTool = null
}

DrawPlugin.prototype.addPoint = function (lon, lat, alt, throwEvent = true) {
    if (this.currentTool && AnkaScalable.ScalablePlugin.activeGeomDataHolder) {
        var dh = AnkaScalable.ScalablePlugin.activeGeomDataHolder
        dh.addPoint({ lat: lat, lon: lon, alt: alt })
        if (throwEvent) {
            var drawType = this.getGeomType()
            var event = { type: DrawPlugin.STATUS_CHANGE, status: 'POINT_ADDED', lon: lon, lat: lat, alt: alt, isManual: true, drawType: drawType, target: dh }
            this.throwEventFowAll(event)
        }
        this.setDirty()
    } else {
        console.log('DrawingPlugin is not started yet!')
    }
}

DrawPlugin.prototype.setDynamicPoint = function (lon, lat, alt) {
    if (this.currentTool && AnkaScalable.ScalablePlugin.activeGeomDataHolder) {
        var dh = AnkaScalable.ScalablePlugin.activeGeomDataHolder
        dh.setDynamicPoint({ lat: lat, lon: lon, alt: alt })
        var drawType = this.getGeomType()
        var event = { type: DrawPlugin.STATUS_CHANGE, status: 'POINT_MOVING', lon: lon, lat: lat, alt: alt, isManual: true, drawType: drawType, target: dh }

        this.throwEventFowAll(event)
        this.setDirty()
    } else {
        console.log('DrawingPlugin is not started yet!')
    }
}

DrawPlugin.prototype.setGeoJSONToCurrentDraw = function (geojson) {
    if (this.currentTool && AnkaScalable.ScalablePlugin.activeGeomDataHolder) {
        if (geojson) {
            var properties = geojson.properties
            var geometry = geojson.geometry
            var type = geometry.type
            var coordinates = geometry.coordinates
            var layer = this.layer

            if (type === 'Point') {
                let point = { lon: coordinates[0], lat: coordinates[1], alt: coordinates[2] }
                let drawType = this.getGeomType()
                let dh = AnkaScalable.ScalablePlugin.activeGeomDataHolder
                dh.setPoints([point])
                dh.isDrawnData = true
                if (layer) {
                    dh.layer = layer
                } else {
                    this.sketchLayer = this.scalable.getMainSketchLayer()
                    dh.layer = this.sketchLayer
                }
                dh.mergeAttributes(properties)
                dh.setStatus(AnkaScalable.GeomDataHolder.STATUS.COMPLETED)

                if (layer.isSketchLayer) {
                    layer.addToList(dh)
                    let ins = layer.getLayerInstances()
                    for (let i = 0; i < ins.length; i++) {
                        ins[i].drawGeometry(dh)
                    }
                } else {
                    let ins = layer.getLayerInstances()
                    for (let i = 0; i < ins.length; i++) {
                        ins[i].addToList(dh)
                        ins[i].drawGeometry(dh)
                    }
                }

                let ins = DrawPlugin._instances
                for (let i = 0; i < ins.length; i++) {
                    ins[i].endDraw()
                }

                let event = { type: DrawPlugin.STATUS_CHANGE, target: dh, status: 'FINISHED', isManual: true, drawType: drawType, isSuccessful: true }
                this.throwEventFowAll(event)
            } else if (type === 'LineString') {
                let points = []
                for (let i = 0; i < coordinates.length; i++) {
                    var p = coordinates[i]
                    points.push({ lon: p[0], lat: p[1], alt: p[2] })
                }

                let drawType = this.getGeomType()
                let dh = AnkaScalable.ScalablePlugin.activeGeomDataHolder
                if (layer) {
                    dh.layer = layer
                } else {
                    this.sketchLayer = this.scalable.getMainSketchLayer()
                    dh.layer = this.sketchLayer
                }
                dh.isDrawnData = true
                dh.setPoints(points)
                dh.mergeAttributes(properties)
                dh.setStatus(AnkaScalable.GeomDataHolder.STATUS.COMPLETED)

                if (layer.isSketchLayer) {
                    layer.addToList(dh)
                    let ins = layer.getLayerInstances()
                    for (let i = 0; i < ins.length; i++) {
                        ins[i].drawGeometry(dh)
                    }
                } else {
                    let ins = layer.getLayerInstances()
                    for (let i = 0; i < ins.length; i++) {
                        ins[i].addToList(dh)
                        ins[i].drawGeometry(dh)
                    }
                }

                let ins = DrawPlugin._instances
                for (let i = 0; i < ins.length; i++) {
                    ins[i].endDraw()
                }

                let event = { type: DrawPlugin.STATUS_CHANGE, target: dh, status: 'FINISHED', isManual: true, drawType: drawType, isSuccessful: true }
                this.throwEventFowAll(event)
            } else if (type === 'Polygon') {
                console.log('Non Implemented format')
            } else {
                console.log('Unsupportted geometry format ' + type)
            }
        } else {
            console.log('GeoJSON is not defined')
        }
    } else {
        console.log('DrawingPlugin is not started yet!')
    }
}

DrawPlugin.prototype.lineStatus = function (e) {
    if (e.status === 'POINT_ADDED') {
        let ins = DrawPlugin._instances
        for (let i = 0; i < ins.length; i++) {
            if (ins[i] !== this) {
                ins[i].currentTool.raisePointCount()
                ins[i].currentTool.scalable.addSketchPoint(e.lon, e.lat)
            }
        }
    } else if (e.status === 'POINT_MOVING') {
        let ins = DrawPlugin._instances
        for (let i = 0; i < ins.length; i++) {
            if (ins[i] !== this) {
                ins[i].currentTool.scalable.updateLastSketchPosition(e.lon, e.lat, e.alt, true)
            }
        }
    } else if (e.status === 'FINISHED') {
        let ins = DrawPlugin._instances
        for (let i = 0; i < ins.length; i++) {
            if (ins[i] !== this) {
                ins[i].currentTool.stopPolygonDrawingSlience(e.isSuccessful)
            }
        }
    }
}

DrawPlugin.prototype.pointStatus = function (e) {
    if (e.status === 'POINT_ADDED') {
        let ins = DrawPlugin._instances
        for (let i = 0; i < ins.length; i++) {
            if (ins[i] !== this) {
                ins[i].currentTool.scalable.addPersistentPoint(e.lon, e.lat)
            }
        }
    } else if (e.status === 'FINISHED') {
        let ins = DrawPlugin._instances
        for (let i = 0; i < ins.length; i++) {
            if (ins[i] !== this) {
                ins[i].currentTool.stopDrawingSlience(e.isSuccessful)
            }
        }
    }
}

DrawPlugin.prototype.getCurrentGeometry = function () {
    return AnkaScalable.ScalablePlugin.activeGeomDataHolder
}

export { DrawPlugin }
