import { Tool } from './Tool'
import { movingPointCreator } from './EventCreator';
import GEOM_TYPES from './GeomTypes';

function PolygonTool () {
    Tool.apply(this, arguments)
    this.type = GEOM_TYPES.Polygon
}

PolygonTool.prototype = Object.assign(Object.create(Tool.prototype),
    {
        constructor: PolygonTool,
        startDrawing: function () {
            Tool.prototype.startDrawing.apply(this, [-1])
        },

        stopDrawing: function () {
            Tool.prototype.stopDrawing.apply(this, arguments)
        },

        stopPolygonDrawingSlience: function (isSuccessful) {
            Tool.prototype.stopDrawingSlience.apply(this, arguments)
            this.scalable.stopSketchDrawing(isSuccessful)
        },

        groundMove: function (e) {
            Tool.prototype.groundMove.apply(this, arguments)
            if (this.scalable.activeGeom.canDraw) {
                this.enableClick()
            } else {
                this.disableClick()
            }
            
            this.throwEvent(movingPointCreator(e.lon, e.lat, e.alt, this.scalable.activeGeom))
        },

        contextClick: function (e) {
            this.stopDrawing(this.scalable.activeGeom.points.length >= 3)
        },

        onGroundLeave: function () {
            Tool.prototype.onGroundLeave.apply(this, arguments)
        },

        onGroundClick: function (e) {
            if (this.clickDisabled) return

            this.raisePointCount()
            this.throwEvent({ type: Tool.STATUS_CHANGE, status: 'POINT_ADDED', lon: e.lon, lat: e.lat, alt: e.alt, target: this.scalable.activeGeom })
        }

    })

export { PolygonTool }
