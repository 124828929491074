import GEOM_TYPES from "./GeomTypes";

SRoadLine.isMultiGeom = false
function SRoadLine (scalable, geomdatholder) {
    AnkaScalable.SGeom.apply(this, arguments)
    this.type = GEOM_TYPES.RoadLine
    this.points = []
    this.scalable = scalable
    this.softText = scalable.softText

    this.neighbourSize = 0

    if (!AnkaScalable.SHLine.__hookTexture) {
        var t = AnkaPanAPI.AssetManager.getInstance().GetAssetNoCache('img/scalable/hook.jpg')
        t.generateMipmaps = false
        t.minFilter = THREE.LinearFilter
        AnkaScalable.SHLine.__hookTexture = t
    }

    if (!AnkaScalable.SHLine.__hookGeom) {
        var geo = new THREE.Geometry()
        var vertex = new THREE.Vector3()
        geo.vertices.push(vertex)
        AnkaScalable.SHLine.__hookGeom = geo
    }

    this._lineColor = new THREE.Color(1, 0, 1)
    var material = new THREE.LineBasicMaterial({
        color: this._lineColor,
        linewidth: 1,
        depthTest: true,
        transparent: true,
        depthWrite: false
    })

    this.hookGeom = AnkaScalable.SHLine.__hookGeom

    var pm = new THREE.PointsMaterial({ size: 8, sizeAttenuation: false, map: AnkaScalable.SHLine.__hookTexture, depthTest: true, color: 0x00ff00, transparent: false })
    this.hookMaterial = pm
    this.bottomHook = new THREE.Points(this.hookGeom, this.hookMaterial)
    this.add(this.bottomHook)

    var vertexCount = 3
    this.range = vertexCount
    var geom = new THREE.BufferGeometry()
    var positions = new Float32Array(vertexCount * 3) // 3 vertices per point
    geom.addAttribute('position', new THREE.BufferAttribute(positions, 3))

    this.line = new AnkaScalable.EventLine(geom, material)
    this.line.frustumCulled = false
    this.add(this.line)

    this.labelHook = new THREE.Object3D()

    this._raycasterForMouse = new THREE.Raycaster()
    this._mousePos = new THREE.Vector3()
}

SRoadLine.prototype = Object.assign(Object.create(AnkaScalable.SGeom.prototype),
    {
        constructor: SRoadLine,

        enable: function (panogl) {
            if (!this.enabled) {
                this.openGeom = true
                this.panogl = panogl
                var cam = panogl.getMainCamera()
                this.canDraw = true
                this.line.setClickable(true, this.panogl, null, cam)
                this.line.addEvent(AnkaScalable.SGeom.CLICK, this, this.onClick)
                this.line.addEvent(AnkaScalable.SGeom.MOUSE_DOWN, this, this.onDown)
            }
        },
        stopDraw: function () {
            this.line.frustumCulled = true
            this.line.geometry.computeBoundingSphere()

            this.update()
            this.setLabel()
        },

        onClick: function (e) {
            e.currentTarget = this
            this.throwEvent(e)
        },

        onSelect: function () {
            this.line.material.color = new THREE.Color(0, 1, 1)
        },

        onDeSelect: function () {
            this.updateStyle()
        },

        setData: function (points, atts) {
            if (points.length > 0) {
                this.attributes = atts
                this.points.length = 0
                for (var i = 0; i < points.length; i++) {
                    this.points.push(points[i])
                }

                this.bottomHook.visible = true
                this.bottomHook.position.set(0, 0, 0)
            } else {
                this.points.length = 0
            }
        },

        getRayDirection: function () {
            var cam = this.panogl.getMainCamera()
            var mx = this.panogl.globalMouseOffsetX
            var my = this.panogl.globalMouseOffsetY

            var cnv = this.panogl.getRendererDom()
            this._mousePos.x = (mx / cnv.width) * 2 - 1
            this._mousePos.y = -(my / cnv.height) * 2 + 1
            this._raycasterForMouse.setFromCamera(this._mousePos, cam)
            var rayDirection = this._raycasterForMouse.ray.direction
            return rayDirection
        },

        setDynamicPoint: function (e) {
            this.points[this.points.length] = e
            this.update()
        },

        update: function () {
            var lineAr = this.line.geometry.attributes.position.array
            var index = 0
            for (var i = 0; i < this.points.length; i++) {
                var p = this.points[i]
                var pos = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)

                lineAr[index++] = pos.x
                lineAr[index++] = pos.y
                lineAr[index++] = pos.z
            }

            if (this.points.length > 2) {
                var dx = lineAr[6] - lineAr[3]
                var dz = lineAr[8] - lineAr[5]

                var halfDist = Math.sqrt((dx * dx) + (dz * dz)) / 2
                var rad = Math.atan2(dx, dz)

                this.labelHook.position.set(lineAr[3] + halfDist * Math.sin(rad), lineAr[4], lineAr[5] + halfDist * Math.cos(rad))

                var haversine = AnkaPanAPI.Utils.haversine(this.points[1].lat, this.points[2].lat, this.points[1].lon, this.points[2].lon)
                this.attributes['_length'] = haversine

                this.setLabel()
            }

            this.line.geometry.setDrawRange(0, this.range)
            this.line.geometry.attributes.position.needsUpdate = true

            this.scalable.setDirty()
        },

        updateStyle: function () {
            if (this.__layer) {
                var style = this.__layer.getStyle()

                this.line.material.color = new THREE.Color(style.lineColor)
                this.line.material.opacity = style.lineOpacity
            }
        },

        setLabel: function () {
            this.softText.removeObject3D(this.labelHook)

            if (this.points.length > 2) {
                var cam = this.panogl.getMainCamera()
                var text = this.getLabelText()
                text = Math.round(text * 100) / 100
                this.softText.addObject3D(this.labelHook, cam, text + 'm')
                this.add(this.labelHook)
            }
        },

        destroy: function () {
            if (this.line) {
                if (this.line.material) {
                    this.line.material.dispose()
                }

                if (this.line.geometry) {
                    this.line.geometry.dispose()
                }

                this.line.setClickable(false)
            }

            if (this.neighbourEdge) {
                this.neighbourEdge.material.dispose()
                this.oppositeEdge.material.dispose()
                this.neighbourEdge.geometry.dispose()
                this.oppositeEdge.geometry.dispose()

                this.neighbourEdge.parent.remove(this.neighbourEdge)
                this.oppositeEdge.parent.remove(this.oppositeEdge)
            }

            if (this.labelHook) {
                if (this.labelHook.parent) {
                    this.labelHook.parent.remove(this.labelHook)
                }

                this.softText.removeObject3D(this.labelHook)
            }

            if (this.parent) {
                this.parent.remove(this)
            }
        }
    })

export { SRoadLine }
