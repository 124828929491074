
Anchor3D.UPDATE = 'update'

function Anchor3D(drawingPlugin, scene, camera) {
    this.drawingPlugin = drawingPlugin
    this.panogl = drawingPlugin.baseObject
    this.scene = scene
    this.camera = camera
    var boxGeom = new THREE.PlaneBufferGeometry(5, 5, 1, 1)

    if (!Anchor3D.__AnchorMaterial) {
        var tloader = new THREE.TextureLoader()
        Anchor3D.__AnchorMaterial = tloader.load('img/draw/drag_anchor.png', function () {
            AnkaPanAPI.PanoGL.waitingForGlobalRender = true
        })
    }

    var material = new THREE.MeshBasicMaterial({ transparent: true, map: Anchor3D.__AnchorMaterial, depthTest: true, depthWrite: true })
    AnkaScalable.EventMesh.apply(this, [boxGeom, material])

    this.userData.clickPriority = 1000

    if (!Anchor3D.__AnchorUp) {
        var lineGeom = new THREE.BoxBufferGeometry(1, 10, 1, 1, 1, 1)
        var line = new THREE.Mesh(lineGeom, new THREE.MeshBasicMaterial({ color: 0xffff00 }))
        line.position.y = 5 + 2.5

        var coneGeometry = new THREE.ConeBufferGeometry(3, 10, 15)
        var cone = new AnkaScalable.EventMesh(coneGeometry, new THREE.MeshBasicMaterial({ color: 0x00ff00 }))
        line.add(cone)
        cone.position.y = 7.5
        Anchor3D.__AnchorUp = line
        Anchor3D.__AnchorConeUp = cone
    }

    this.setClickable(true, this.panogl, scene, camera)
    this.panogl.addEvent(AnkaPanAPI.PanoGL.VIEW_ANGLE_CHANGE, this, this.updateScale)
}

Anchor3D.prototype = Object.assign(Object.create(AnkaScalable.EventMesh.prototype),
    {
        constructor: Anchor3D,
        setSelection: function (b) {
            if (this.__isSelected !== b) {
                Anchor3D.__AnchorConeUp.setClickable(false)
                if (Anchor3D.__AnchorConeUp.hasEvent(AnkaPanAPI.MeshMousePicker.MOUSE_DOWN)) {
                    Anchor3D.__AnchorConeUp.removeEvent(AnkaPanAPI.MeshMousePicker.MOUSE_DOWN, this._mouseDownOnAnchorUp)
                }

                if (b) {
                    this.add(Anchor3D.__AnchorUp)
                    Anchor3D.__AnchorConeUp.setClickable(true, this.panogl, this.scene, this.camera)
                    Anchor3D.__AnchorConeUp.addEvent(AnkaPanAPI.MeshMousePicker.MOUSE_DOWN, this, this._mouseDownOnAnchorUp)
                } else {
                    this.remove(Anchor3D.__AnchorUp)
                }

                this.__isSelected = b
            }
        },

        _mouseDownOnAnchorUp: function (evt) {
            this.___groundPositionY = this.position.y
            this.__onAnchorMouseYPos = this._getAnchorPos()
            this.drawingPlugin.stopDragController(true)

            this.___releaseAnchorFNC = this.mouseUpOnDocument.bind(this)
            document.addEventListener('mouseup', this.___releaseAnchorFNC)
            this.panogl.addEvent(AnkaPanAPI.PanoGL.PRE_RENDER, this, this.onMoveOnY)
        },

        startDrag: function () {
            this.___groundPositionY = this.position.y
            this.__mouseDownPos = this._getPositionOnGround()
            this.panogl.addEvent(AnkaPanAPI.PanoGL.PRE_RENDER, this, this.onMoveOnXZ)
        },

        mouseUpOnDocument: function (e) {
            this.drawingPlugin.stopDragController(false)
            document.removeEventListener('mouseup', this.___releaseAnchorFNC)
            this.panogl.removeEvent(AnkaPanAPI.PanoGL.PRE_RENDER, this.onMoveOnXZ)
            this.panogl.removeEvent(AnkaPanAPI.PanoGL.PRE_RENDER, this.onMoveOnY)
        },

        setPoint: function (point, tempMesh, geomHolder) {
            this.tempMesh = tempMesh
            this.geomHolder = geomHolder
            this.point = point

            if (this.point.alt === undefined || this.point.alt == null & isNaN(this.point.alt)) {
                var currentP = this.panogl.getCurrentPoint()
                this.point.alt = currentP.altitude
            }
        },

        stopDrag: function () {
            this.panogl.removeEvent(AnkaPanAPI.PanoGL.PRE_RENDER, this.onMoveOnXZ)
        },

        _getAnchorPos: function () {
            var rayDirection = this.__getRayDirection()
            var y = rayDirection.y
            var anchor = this
            var vector = new THREE.Vector3()
            vector.setFromMatrixPosition(anchor.matrixWorld)

            var distance = Math.sqrt((vector.x * vector.x) + (0 * 0) + (vector.z * vector.z))

            var pitch = Math.asin(y)
            var tan = Math.tan(pitch)
            var opposite = distance * tan

            return opposite
        },

        __getRay: function () {
            var panogl = this.panogl
            var camera = this.camera
            var canvas = panogl.getRendererDom()
            var tempVect = new THREE.Vector3()
            var rayCaster = new THREE.Raycaster()

            tempVect.x = (panogl.globalMouseOffsetX / canvas.clientWidth) * 2 - 1
            tempVect.y = -(panogl.globalMouseOffsetY / canvas.clientHeight) * 2 + 1
            rayCaster.setFromCamera(tempVect, camera)

            return rayCaster
        },

        __getRayDirection: function () {
            var rayCaster = this.__getRay()
            return rayCaster.ray.direction
        },

        _getPositionOnGround: function () {
            var camera = this.camera
            var centerVect = new THREE.Vector3(0, -this.___groundPositionY, 0)
            var normalVect = new THREE.Vector3(0, 1, 0)
            var rayCaster = this.__getRay()

            var dist = -(normalVect.dot(camera.position) - normalVect.dot(centerVect)) / normalVect.dot(rayCaster.ray.direction)

            var posVector = new THREE.Vector3()
            posVector.x = camera.position.x - rayCaster.ray.direction.x * dist
            posVector.y = -centerVect.y// camera.position.y - rayCaster.ray.direction.y * dist;
            posVector.z = camera.position.z - rayCaster.ray.direction.z * dist
            return posVector
        },

        updateScale: function () {
            var dist = Math.sqrt((this.position.x * this.position.x) + (this.position.z * this.position.z) + (this.position.y * this.position.y))
            var vFOV = this.camera.fov * Math.PI / 180 // convert vertical fov to radians
            var height = 2 * Math.tan(vFOV / 2) * dist // visible height
            var x = height / 120
            this.scale.set(x, x, x)
        },

        onMoveOnY: function (e) {
            var yPos = this._getAnchorPos()
            var newPosY = yPos - this.__onAnchorMouseYPos
            var anchor = this
            anchor.position.y += newPosY
            this.__onAnchorMouseYPos = yPos

            var camHeightInMeter = this.panogl.getCameraHeight()
            var camInPixel = this.panogl.getGroundPos()

            var realHeightOffset = (anchor.position.y / camInPixel) * camHeightInMeter
            var currentAlt = 0
            var p = this.panogl.getCurrentPoint()
            if (p) {
                currentAlt = p.altitude + realHeightOffset
            }
            this.point.alt = currentAlt
            this.lookAtCamera()
            this.updateTempGeometry()
        },

        onMoveOnXZ: function (e) {
            var pos = this._getPositionOnGround()

            var offset = new THREE.Vector3()
            offset.subVectors(pos, this.__mouseDownPos)

            this.position.add(offset)
            if (pos) {
                this.position.set(pos.x, pos.y, pos.z)
                this.__mouseDownPos = pos
            }

            var panogl = this.panogl
            var gm = panogl.getGroundMaster()
            var lastPos = gm.posToLoc(this.position)

            var offsetLat = lastPos.lat - this.point.lat
            var offsetLon = lastPos.lon - this.point.lon

            this.point.lat += offsetLat
            this.point.lon += offsetLon

            this.lookAtCamera()
            this.updateTempGeometry()
        },

        updateTempGeometry: function () {

            this.geomHolder.notifyPointChange()

            // this.tempMesh.setData(this.geomHolder.points, this.geomHolder.attributes, this.geomHolder.holes)
            // this.tempMesh.update()
            this.updateScale()
            // this.tempMesh.__layer._updateOtherInstanceGeometries(this.geomHolder)
            this.throwEvent({ type: Anchor3D.UPDATE })
        },

        lookAtCamera: function () {
            this.rotation.y = Math.atan2(this.position.x, this.position.z) + Math.PI
        },

        updatePosition: function () {
            var scale = this.drawingPlugin.scalable
            var p = this.point
            var pxPos = scale.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)
            this.position.set(pxPos.x, pxPos.y, pxPos.z)
            this.lookAtCamera()
            this.updateScale()
        },

        destroy: function () {
            this.panogl.removeEvent(AnkaPanAPI.PanoGL.VIEW_ANGLE_CHANGE, this.updateScale)

            if (this.parent) {
                this.parent.remove(this)
            }

            this.geometry.dispose()
            this.material.dispose()

            this.setClickable(false)

            let coniAnchor = Anchor3D.__AnchorConeUp
            if (coniAnchor) {
                coniAnchor.setClickable(false)
            }
        }

    })

export { Anchor3D }
